<template>
 <div id="plan1" class="plan" v-title :data-title="title">
   <page-template :carousel-list="carouselList"
                  type="product"
                  :plan1-active="plan1Active"
                  :plan1-details="plan1Details"
                  :plan2-title="plan2Title"
                  :plan2-list="plan2List"
                  :plan3-list="plan3List"
                  />
 </div>
</template>

<script>
import PageTemplate from '../components/template.vue'
import Public from "@/assets/js/public";
export default {
  name: 'Product1',
  components: {
   PageTemplate
  },
  mixins: [],
  data () {
    return {
      title: this.$route.query.title || '产品服务',
      // 轮播列表
      carouselList: [
        { img: require('@/assets/images/01.jpg'), txt: '个性化配置办理流程', txt2: '少跑腿、', txt3: '教师少盖章', txt5: '让毕业生', jumpUrl: 'btn'},
      ],
      plan1Active: Public.product1().routerName,
      // 当前方案概述详情
      plan1Details: Public.product1(),
      plan2Title: {
        title: '产品亮点', txt: 'Product Highlights'
      },
      // 我们的经验
      plan2List: [
        {img: require('@/assets/images/04.fw.png'), title: '线上清单', txt: '线上真实可操作清单，除图书归还，不用学生跑路，线上即办理。'},
        {img: require('@/assets/images/05.fw.png'), title: '自动核对事项状态', txt: '所有事项，学校可提前核对，对于已经完成的事项，自动为学生办结'},
        {img: require('@/assets/images/06.fw.png'), title: '自动完结清单', txt: '学生办完后，自动改变清单状态，需要资料可直接去领取，且自动开具离校证明'},
        {img: require('@/assets/images/07.fw.png'), title: '定制化毕业事项流程', txt: '支持按照学校需求个性化配置办理流程'},
      ],
      // 服务案例
      plan3List: [
        {img: require('@/assets/images/30.fw.png'), title: '河南师范大学', txt: '是国家中西部高等教育振兴计划支持高校、国家“111计划”实施高校'},
        {img: require('@/assets/images/school/zxk.jpg'), title: '郑州信息科技职业学院', txt: '2002年由河南省政府批准成立的一所专科层次的全日制普通高职院校'},
        {img: require('@/assets/images/32.fw.png'), title: '新乡学院', txt: '是一所经教育部批准建立的公办全日制普通本科院校。学校始建于1949年'},
      ]
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {

  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
